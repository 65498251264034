@mixin center-content {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;

	height: calc(100% - #{var(--desktop-header-height)});
	align-content: center;

	@media screen and (max-device-width: 1024px) {
	}
}

@mixin card($color) {
	background-color: white;
	border: 2px solid var(--ion-color-primary);
	border-radius: 20px;
	//box-shadow: 0 7px 33px rgba(0, 0, 0, .5);
	margin: 18px;
	text-align: center;

	.icon {
		background-color: $color;
		margin: 46px auto 22px auto;
		height: 65px;
		width: 58px;

        @media screen and (max-width: 1024px) {
            display: inline-block;
            margin: 18px;

            height: 46px;
            width: 41px;
        }
	}

	p {
		color: var(--ion-color-primary);
		font-size: 22px;
		font-weight: normal;
		margin: 0;

        @media screen and (max-width: 1024px) {
            font-size: 20px;
        }
	}

	&__label {
		margin: 0 8px 0 0;
	}

	&__arrow {
		display: none;
	}

	@media screen and (min-width: 1024px) {
		height: 200px;
		width: 250px;
	}

	@media screen and (max-width: 1024px) {
		$icon-width: 78px;
		$arrow-width: 22px;

		margin: 9px 18px;
		display: flex;
		align-items: center;

		width: 100%;
		height: auto;

		&:first-of-type {
			margin-top: 18px;
		}

		&:last-of-type {
			margin-bottom: 18px;
		}

		&__label {
			display: inline-block;
			text-align: left;

			width: calc(100% - #{$icon-width} - 18px - #{$arrow-width});
		}

		&__arrow {
			color: var(--ion-color-primary);
			display: flex;

			width: $arrow-width;

			ion-icon {
				font-size: $arrow-width;
				width: 100%;
			}
		}
	}
}

@mixin button() {
    border-radius: var(--border-radius-button);
    font-size: 22px;
    font-weight: lighter;
    text-transform: capitalize;

    height: 60px;
    width: 360px;

    @media screen and (max-device-width: 1024px) {
        font-weight: normal;
        width: 100%;
    }
}

@mixin button-solid($background-color, $color) {
    @include button;

	background-color: $background-color;
	color: $color;

	&:disabled {
		opacity: .5;
		background-color: var(--ion-color-medium);
	}
}

@mixin button-outline($color) {
    @include button;

	background-color: transparent;
	border: 2px solid $color;
	color: $color;

	&:disabled {
		opacity: .5;
		color: var(--ion-color-medium);
		border: 2px solid var(--ion-color-medium);
	}
}
