// Modal Styles
// https://ionicframework.com/docs/api/modal/#usage
@import "./base";

.full {
	--height: 100%;
	--width: 100%;

	ion-header {
		ion-toolbar {
			--background: var(--ion-color-primary);
			--color: var(--ion-color-primary-contrast);
		}
	}
}

/*
	Overlay
 */
.verified-modal::part(content) {
    --height: 100%;
    --width: 100%;
    --background: var(--ion-color-success);
}

.verification-failed-modal::part(content) {
    --height: 100%;
    --width: 100%;
    --background: var(--ion-color-danger);
}

.error-modal::part(content) {
    --height: 100%;
    --width: 100%;
    --background: var(--ion-color-danger);
}

.info-modal::part(content) {
    --height: 100%;
    --width: 100%;
    --background: var(--ion-color-medium);
}

.warning-modal::part(content) {
    --height: 100%;
    --width: 100%;
    --background: var(--ion-color-warning-shade);
}

.offline-modal::part(content) {
    --height: 100%;
    --width: 100%;
    --background: var(--ion-color-medium);
}

/*
	Select Patient List
 */
.select-patient-modal::part(content) {
    --height: 100%;
    --width: 100%;
}

/*
	Recipe Calculator
 */
.recipe-calculator-modal::part(content) {
    --height: 100%;
    --width: 100%;
}

.milk-state-action-sheet .action-sheet-wrapper {
    --height: 100%;
    --width: 100%;
    .action-sheet-group:nth-of-type(1) {
        --button-color: var(--ion-color-primary);
        border-radius: 10px 10px 0 0;

        .action-sheet-title {
            color: var(--ion-color-primary);
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}

.receive-milk-modal::part(content) {
    --height: 100%;
    --width: 100%;
    ion-buttons > ion-button, ion-buttons > ion-button.button-clear {
        --color: white;
        color: white;
        font-size: 14px;
        text-transform: uppercase;
    }
}

.not-received-modal::part(content) {
    --height: 100%;
    --width: 100%;
}

.receive-product-modal::part(content) {
	--height: 100%;
	--width: 100%;
}

.fullscreen-modal::part(content) {
   	--height: 100%;
	--width: 100%;
}

.datetime-modal::part(content) {
    //--height: 389px;
    --height: 600px;
    --width: 350px;

    color: black;
}

/**
  * Barcode Scanning
  * -------------------------------------------
  */
  body.barcode-scanning-modal-active {
	visibility: hidden;
	--background: transparent;
	--ion-background-color: transparent;
  }

  .barcode-scanning-modal {
	visibility: visible;
  }

  @media (prefers-color-scheme: dark) {
	.barcode-scanning-modal {
	  --background: transparent;
	  --ion-background-color: transparent;
	}
  }
