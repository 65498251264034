/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Components */
@import "./theme/components/index.scss";

/* Import fonts */
@font-face {
	font-family: "OpenSans";
	src: url(./assets/fonts/OpenSans-ExtraBold.ttf);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: "OpenSans";
	src: url(./assets/fonts/OpenSans-Bold.ttf);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "OpenSans";
	src: url(./assets/fonts/OpenSans-Semibold.ttf);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: "OpenSans";
	src: url(./assets/fonts/OpenSans-Italic.ttf);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "OpenSans";
	src: url(./assets/fonts/OpenSans-Regular.ttf);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "OpenSans";
	src: url(./assets/fonts/OpenSans-Light.ttf);
	font-style: normal;
	font-weight: 300;
}

*:focus {
	outline: none;
}

*:not(ion-icon) {
	font-family: "Open Sans", sans-serif;
}

h1 {
	color: var(--ion-color-primary);
	font-size: 32px;
	font-weight: lighter;
	text-align: center;
	margin: 0;

	@media screen and (max-device-width: 1024px) {
		font-weight: normal;
	}
}

h2 {
	color: var(--ion-color-primary);
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 1px;
	margin: 30px 0;
	text-align: center;
	text-transform: uppercase;
}

h3 {
	color: var(--ion-color-primary);
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 1px;
	margin: 0 0 4px 0;
	text-transform: uppercase;
}

p {
	font-size: 16px;
}

ion-title {
	color: var(--ion-color-primary-contrast);
	font-size: 16px;
	font-weight: normal;
	letter-spacing: 4px;
	text-align: center;
	text-transform: uppercase;
	padding: 0;

	@media screen and (max-device-width: 1024px) {
		font-size: 14px;

		.ion-text-wrap {
			width: 50%;
			margin: 0 auto;
		}
	}
}

ion-toolbar {
	display: flex;
	height: var(--desktop-header-height);
	padding: 0 var(--desktop-horizontal-padding);

	@media screen and (max-device-width: 1024px) {
		display: flex;
		height: auto;
		padding: var(--mobile-horizontal-padding);

		ion-header ion-toolbar.md {
			padding-top: var(--mobile-horizontal-padding);
		}

		// Need padding for the notch in iPhone X+
		ion-header ion-toolbar.ios {
			padding-top: calc(var(--ios-statusbar-height) + var(--mobile-horizontal-padding));
		}
	}

	ion-searchbar {
		--background: var(--ion-color-primary-contrast);
		--border-radius: var(--border-radius-input);
		--box-shadow: none;
		--color: var(--ion-color-primary);
		--icon-color: var(--ion-color-primary);
		--placeholder-color: var(--ion-color-primary);

		margin: 0 auto;
		max-width: 1024px;
		padding: 0;

		@media screen and (max-width: 1024px) {
			--placeholder-color: var(--ion-color-primary-contrast);
		}
	}

    ion-buttons {
        &:first-of-type {
            display: flex;
            align-items: center;
            position: absolute;
            left: 0;

            button {
                --padding-inline-start: 0;
            }

            ion-button {
                --border-radius: var(--border-radius-toolbar-button);
                --padding-start: 14px;
                --padding-end: 14px;

                color: var(--ion-color-primary-contrast);
                background-color: transparent;
                border: 1px solid var(--ion-color-primary-contrast);
                border-radius: var(--border-radius-toolbar-button);
                font-size: 16px;
                font-weight: lighter;
                letter-spacing: 0;
                margin: 0;
                text-transform: capitalize;

                @media screen and (max-device-width: 1024px) {
					--padding-start: 8px;
					--padding-end: 8px;

                    border: none;
                    font-size: 14px;
                    font-weight: normal;
                    padding: 0;
                }
            }
        }

        &:last-of-type {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;

            color: var(--ion-color-primary-contrast);
            font-size: 16px;
            font-weight: lighter;
            text-transform: capitalize;

            ion-select {
                color: var(--ion-color-primary-contrast);
                background-color: transparent;
                border: 1px solid var(--ion-color-primary-contrast);
                border-radius: var(--border-radius-toolbar-button);
                font-size: 16px;
                font-weight: lighter;
				height: 32px;
                margin: 0;
                text-transform: capitalize;
				padding: 0 8px;

                @media screen and (max-device-width: 1024px) {
                    border: none;
                    font-size: 14px;
                    font-weight: normal;
                    padding: 0 8px;
                }
            }
        }
    }
}

ion-content > div {
	max-width: 1024px;
	margin: 0 auto;

    & ion-list {
        padding-top: 0;
        padding-bottom: 0;
    }
}

ion-input.input-fill-outline.sc-ion-input-md-h {
	--border-color: var(--ion-color-primary);
	--border-radius: var(--border-radius-input);
	--padding-start: 14px;
	--padding-end: 14px;
}

ion-input, ion-select {
	--padding-start: 14px;
	--padding-end: 14px;
	--placeholder-color: var(--ion-color-primary);
	--placeholder-opacity: 0.5;
	--highlight-color-valid: var(--ion-color-primary);
	--border-color: var(--ion-color-primary);
	--border-radius: var(--border-radius-input);
	--background: var(--ion-color-primary-contrast);

	display: flex;
	min-height: 44px !important;
	height: 44px;
	width: 100%;

	background-color: var(--ion-color-primary-contrast);
	color: var(--ion-color-primary);
	font-size: 16px;

	&:disabled {
		color: rgba(255, 255, 255, .5);
	}
}

ion-item[aria-disabled="true"] {
	ion-label {
		opacity: 1;
		ion-input {
			input[disabled] {
				opacity: 0.6 !important;
			}
		}
	}
}

.section-header {
	color: var(--ion-color-primary);
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 16px 0 8px 0;
    padding: 0;

	ion-chip {
		font-weight: normal;
	}
}

ion-list-header {
    color: var(--ion-color-primary);
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 16px 0 8px 0;
    padding: 0;

    ion-button.select-patient-btn {
        --border-radius: 28px;
        --padding-top: 10px;
        --padding-bottom: 10px;

        border: 1px solid var(--ion-color-primary);
        border-radius: 28px;
        font-size: 16px;
        font-weight: normal;
        height: 30px;
        letter-spacing: 0;
        text-transform: capitalize;

        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
    }

	ion-button.select-milk-btn {
		--border-radius: 28px;
		--padding-top: 10px;
		--padding-bottom: 10px;

		background-color: var(--ion-color-warning);
		border-radius: 28px;
		color: black;
		font-size: 16px;
		font-weight: normal;
		height: 30px;
		letter-spacing: 0;
		text-transform: capitalize;

		@media screen and (max-width: 1024px) {
			font-size: 14px;
		}
	}

    ion-button.action-btn {
        --border-radius: 28px;
        --padding-top: 10px;
        --padding-bottom: 10px;

        border: 1px solid var(--ion-color-primary);
        border-radius: 28px;
        font-size: 16px;
        font-weight: normal;
        height: 30px;
        letter-spacing: 0;
        text-transform: capitalize;

        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
    }
}

ion-accordion {
    ion-item {
        --border-color: rgba(255, 255, 255, .3);
        --detail-icon-color: var(--ion-color-primary);
        cursor: pointer;

        @media screen and (max-width: 1024px) {
            --padding-start: 0;
            --padding-end: 0;
            --inner-padding-start: 0;
            --inner-padding-end: 0;
        }

        & > ion-label {
            color: var(--ion-color-primary);
            font-size: 16px;

            @media screen and (max-width: 1024px) {
            }
        }

        ion-select {
            margin: 0;
			max-width: 100%;
        }

        ion-input {
            margin: 0;
            width: calc(60% - 30px);
        }

        ion-button {
            margin: 0;
        }

        ion-icon {
            font-size: 16px;
            margin: 0;
        }

        .content__datetime {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        ion-chip {
            // margin: 0;

            & > ion-label {
                align-items: center;
                display: flex;
                font-size: 14px;
                height: 100%;

                @media screen and (max-device-width: 1024px) {
                    font-size: 12px;
                }
            }
        }

        ion-checkbox {
            --border-color: var(--ion-color-primary);
            --border-style: solid;
            --border-width: 1px;

            margin-right: 8px;
        }
    }
}

ion-item-divider {
	--padding-start: 0;
}

ion-item {
	--background-hover: none;
	--background-activated: none;
	--ripple-color: transparent;

	&.item-disabled {
		opacity: 0.5;
	}
}

form {
	//ion-input, ion-select {
	//	--placeholder-color: var(--ion-color-primary);
	//	--placeholder-opacity: 0.5;
	//}
}

// Applies a text wrap on ion options
.alert-tappable.alert-radio {
	height: auto;
	contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
	white-space: normal;
}

.popover-datetime__content {
	display: flex;
	justify-content: center;
}

.select-option-wrap .select-interface-option ion-label {
    white-space: normal;
}

fa-icon {
	color: var(--ion-color-primary);

	&.is-dark-mode {
		color: #fff;
	}

	&.is-button {
		color: #000;
	}
}

// https://github.com/ionic-team/ionic-framework/issues/18338#issuecomment-628165897
ion-chip[size="small"] {
	height: 20px;
	font-size: 10px;
	font-weight: bold;
	text-transform: uppercase;
}

ion-modal.bottle-number {
	--width: fit-content;
	--min-width: 300px;
	--height: fit-content;
	--border-radius: 4px;
	--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  }

ion-modal.logout-warning {
	--width: fit-content;
	--min-width: 300px;
	--max-width: 375px;
	--height: fit-content;
	--border-radius: 4px;
	--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
