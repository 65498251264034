.Input {
	margin-bottom: 16px; // match ion-item

	&-label {
		color: var(--ion-color-primary);
		font-weight: normal;
		letter-spacing: 1px;
		margin-bottom: 4px;
		text-transform: uppercase;
		font-size: 14px;
		display: block;

		&__measurement {
			text-transform: none;
		}
	}

	&-field {
		--padding-start: 14px;
		--padding-end: 14px;
		--highlight-color-focused: none;
		--highlight-color-valid: none;
		--highlight-color-invalid: none;

		background-color: var(--ion-color-primary-contrast);
		border: 1px solid var(--ion-color-primary);
		border-radius: var(--border-radius-input);
		color: var(--ion-color-primary);

		&:disabled {
			color: rgba(255, 255, 255, .5);
		}
	}
}
