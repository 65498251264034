.icon-administer-feed {
	mask: url("../assets/imgs/icon_administer-feed.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-assign-product {
	mask: url("../assets/imgs/icon_assign-product.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-divide-feed {
	mask: url("../assets/imgs/icon_divide-feed.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-prep-milk {
	mask: url("../assets/imgs/icon_prep-milk.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-desired-volume {
    mask: url("../assets/imgs/icon_desired-volume.png") no-repeat center;
    mask-size: 100% auto;
}

.icon-direct-breastfeed {
	mask: url("../assets/imgs/icon_direct-breastfeed.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-discharge-parents-milk {
	mask: url("../assets/imgs/icon_discharge-parents-milk.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-duplicate-milk-label {
	mask: url("../assets/imgs/icon_duplicate-milk-label.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-edit-and-reprint-label {
    mask: url("../assets/imgs/icon_edit-and-reprint-label.png") no-repeat center;
    mask-size: 100% auto;
}

.icon-feed-patient {
	mask: url("../assets/imgs/icon_feed-patient.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-feeding-report {
	mask: url("../assets/imgs/icon_feeding-report.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-freeze-parents-milk {
	mask: url("../assets/imgs/icon_freeze-parents-milk.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-prep-formula {
	mask: url("../assets/imgs/icon_prep-formula.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-link-siblings {
	mask: url("../assets/imgs/icon_link-siblings.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-oral-care {
	mask: url("../assets/imgs/icon_oral-care.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-patient-inventory {
	mask: url("../assets/imgs/icon_patient-inventory.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-prep-feed {
	mask: url("../assets/imgs/icon_prep-feed.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-print-fresh-milk-labels {
	mask: url("../assets/imgs/icon_print-fresh-milk-labels.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-manage-labels {
	mask: url("../assets/imgs/icon_manage-labels.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-manual-prep {
	mask: url("../assets/imgs/icon_manual-prep.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-milk-room-prep {
	mask: url("../assets/imgs/icon_milk-room-prep.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-print-parent-labels {
	mask: url("../assets/imgs/icon_print-parent-labels.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-receive-and-discharge-milk {
	mask: url("../assets/imgs/icon_receive-and-discharge-milk.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-receive-product {
	mask: url("../assets/imgs/icon_receive-product.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-receive-parents-milk {
	mask: url("../assets/imgs/icon_receive-parents-milk.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-reports-and-inventory {
	mask: url("../assets/imgs/icon_reports-and-inventory.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-thaw-product {
	mask: url("../assets/imgs/icon_thaw-product.png") no-repeat center;
	mask-size: 90% auto;
}

.icon-thaw-patients-milk {
	mask: url("../assets/imgs/icon_thaw-patients-milk.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-verify-milk-label {
	mask: url("../assets/imgs/icon_verify-milk-label.png") no-repeat center;
	mask-size: 100% auto;
}

.icon-volume-on-hand {
    mask: url("../assets/imgs/icon_volume-on-hand.png") no-repeat center;
    mask-size: 100% auto;
}

.icon-exclamation-point {
	mask: url("../assets/imgs/icon-exclamation-point.png") no-repeat center;
    mask-size: 100% auto;
}
