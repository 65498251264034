// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: #165d73;
	--ion-color-primary-rgb: 22, 93, 115;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #165d73;
	--ion-color-primary-tint: #165d73;

	/** administer feed **/
	--ion-color-administer-feed: #09567c;
	--ion-color-administer-feed-rgb: 9, 86, 124;
	--ion-color-administer-feed-contrast: #ffffff;
	--ion-color-administer-feed-contrast-rgb: 255, 255, 255;
	--ion-color-administer-feed-shade: #09567c;
	--ion-color-administer-feed-tint: #09567c;

	/** prep feed **/
	--ion-color-prep-feed: #70b0a4;
	--ion-color-prep-feed-rgb: 112, 176, 164;
	--ion-color-prep-feed-contrast: #ffffff;
	--ion-color-prep-feed-contrast-rgb: 255, 255, 255;
	--ion-color-prep-feed-shade: #70b0a4;
	--ion-color-prep-feed-tint: #70b0a4;

	/** reports and inventory **/
	--ion-color-reports-and-inventory: #ffca72;
	--ion-color-reports-and-inventory-rgb: 255, 202, 114;
	--ion-color-reports-and-inventory-contrast: #ffffff;
	--ion-color-reports-and-inventory-contrast-rgb: 255, 255, 255;
	--ion-color-reports-and-inventory-shade: #ffca72;
	--ion-color-reports-and-inventory-tint: #ffca72;

	/** manage labels **/
	--ion-color-manage-labels: #e57448;
	--ion-color-manage-labels-rgb: 229, 116, 72;
	--ion-color-manage-labels-contrast: #ffffff;
	--ion-color-manage-labels-contrast-rgb: 255, 255, 255;
	--ion-color-manage-labels-shade: #e57448;
	--ion-color-manage-labels-tint: #e57448;

	/** receive and discharge milk **/
	--ion-color-receive-and-discharge-milk: #6b6c6f;
	--ion-color-receive-and-discharge-milk-rgb: 107, 108, 111;
	--ion-color-receive-and-discharge-milk-contrast: #ffffff;
	--ion-color-receive-and-discharge-milk-contrast-rgb: 255, 255, 255;
	--ion-color-receive-and-discharge-milk-shade: #6b6c6f;
	--ion-color-receive-and-discharge-milk-tint: #6b6c6f;

	/** state based */
	--ion-color-stable-and-opened-products: #615995;
	--ion-color-fresh-ebm: #2aad9a;
	--ion-color-thawed-ebm-and-dm: #eeaf1e;
	--ion-color-frozen-ebm-and-dm: #66caff;

	/** secondary **/
	//--ion-color-secondary: #49597F;
	//--ion-color-secondary-rgb: 73, 89, 127;
	//--ion-color-secondary-contrast: #ffffff;
	//--ion-color-secondary-contrast-rgb: 255, 255, 255;
	//--ion-color-secondary-shade: #49597F;
	//--ion-color-secondary-tint: #6A7796;

	/** tertiary **/
	//--ion-color-tertiary: #5260ff;
	//--ion-color-tertiary-rgb: 82, 96, 255;
	//--ion-color-tertiary-contrast: #ffffff;
	//--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	//--ion-color-tertiary-shade: #4854e0;
	//--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #16a54e;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #830a0d;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	--ion-font-family: "Open Sans", sans-serif;
	--ion-grid-padding: 0;

	// Variables
	--desktop-header-height: 90px;
	--desktop-margin: 16px;
	--desktop-horizontal-padding: 25px;

	--mobile-header-height: 60px;
	--mobile-margin: 8px;
	--mobile-horizontal-padding: 8px;

	--ios-statusbar-height: 34px;

	--border-radius: 8px;
	--border-radius-input: 6px;
	--border-radius-toolbar-button: 8px;
	--border-radius-button: 10px;
}

.ion-color-administer-feed {
	--ion-color-base: var(--ion-color-administer-feed);
	--ion-color-base-rgb: var(--ion-color-administer-feed-rgb);
	--ion-color-contrast: var(--ion-color-administer-feed-contrast);
	--ion-color-contrast-rgb: var(--ion-color-administer-feed-contrast-rgb);
	--ion-color-shade: var(--ion-color-administer-feed-shade);
	--ion-color-tint: var(--ion-color-administer-feed-tint);
}
