@import "./icons";
@import "./mixins";

.menu-button {
	font-size: 24px;
}

.disabled {
	opacity: .3;
}

ion-toast.full-width {
	--min-width: 100%;
	--height: 90px;
	--start: 0;
	--border-radius: 0;
	font-size: 20px;
	margin-top: -8px;

	@media screen and (max-device-width: 1024px) {
		--height: 64px;
	}
}

ion-item.input-field {
	--padding-start: 0;
	--inner-padding-end: 0;
	margin-bottom: 16px;

	ion-label {
		margin: 0;
		max-width: 100%;
		width: 100%;

		p {
			color: var(--ion-color-primary);
			font-weight: normal;
			letter-spacing: 1px;
			margin-bottom: 4px;
			text-transform: uppercase;

			span.measurement
			{
				text-transform: none;
			}
		}
	}

	ion-input, ion-select {
		--padding-start: 14px;
		--padding-end: 14px;

		display: flex;
		min-height: 44px !important;
		height: 44px;
		width: 100%;

		background-color: var(--ion-color-primary-contrast);
		border: 1px solid var(--ion-color-primary);
		border-radius: var(--border-radius-input);

		color: var(--ion-color-primary);
		font-size: 16px;

		&:disabled {
			color: rgba(255, 255, 255, .5);
		}
	}
}

ion-content.md .scan-btn-2 {
	padding: 18px 0;
}

ion-content.ios .scan-btn-2 {
	padding-top: 18px;
	padding-bottom: calc(13px + 18px);
}

.vertical-divider {
	height: 100%;
	width: 1px;
	background-color: var(--ion-color-medium);
	margin: 0 4px;
	opacity: 0.3;
}

.card {
	@include card(var(--ion-color-primary));
}

.card-spacer {
	@include card(var(--ion-color-primary));
	background-color: transparent;
	border: none;
}

.step {
	margin: 0 auto;
	padding-bottom: 90px;

	@media screen and (max-device-width: 1024px) {
		margin: 0 16px;
	}
}

.buttons {
	margin-top: 64px;
	width: 100%;

	@media screen and (max-device-width: 1024px) {
		margin-top: 32px;
		bottom: 0;
	}

	button {
		display: block;
		margin: var(--mobile-margin) auto;
	}

	button.action-btn {
		@include button-outline(var(--ion-color-primary));
	}

	button.nav-btn {
		@include button-solid(
						var(--ion-color-primary),
						var(--ion-color-primary-contrast)
		);

		&--success {
			@include button-solid(
							var(--ion-color-success),
							var(--ion-color-success-contrast)
			);
		}
	}
}

ion-item.qty {
	--padding-start: 0;
	--inner-padding-end: 0;
}

// ML-1736
ion-item[aria-disabled="true"] {
	ion-label {
		opacity: 1;
		ion-input {
			input[disabled] {
				opacity: 0.6 !important;
			}
		}
	}
}

.patient-info2,
.patient-info2--dark,
.milk-info,
.milk-info--dark,
.saved-data {
	ion-item[slot="header"] {
		--padding-start: 0;
	}

	ion-list[slot="content"] {
		border: 1px solid var(--ion-color-primary);
		border-radius: var(--border-radius);
		padding: var(--mobile-margin);
		margin: var(--mobile-margin) 0;
	}

	&--empty {
		border: 1px dashed var(--ion-color-medium);
		border-radius: var(--border-radius);
		margin: 4px;
		padding: 16px;

		span {
			color: var(--ion-color-medium);
		}
	}

	&__header {
		--padding-start: 0;
		//--inner-padding-end: 0;

		ion-list {
			background-color: transparent;
		}

		&__detail {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: 0;

			p {
				margin: 0;
				text-transform: uppercase;
			}

			&__label {
				color: var(--ion-color-primary);
				font-size: 12px;
				letter-spacing: 1px;
			}

			&__value {
				font-size: 18px;
				letter-spacing: 0.5px;

				span:first-child {
					text-transform: uppercase;
				}

				span:last-child {
					text-transform: capitalize;
				}
			}

			&__state {
				font-size: 18px;
				// font-weight: bold;
				text-transform: capitalize;
			}

			@media screen and (max-device-width: 1024px) {
				&__value,
				&__state,
				&__label {
					font-size: 14px;
				}
			}
		}

		&__chip {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@media screen and (max-device-width: 1024px) {
				ion-chip {
					font-size: 12px;
					text-align: center;
				}
			}
		}

		&__toggle {
			ion-col {
				padding: 4px 0;
			}

			button {
				color: var(--ion-color-primary);
				background-color: transparent;
				border: 1px solid var(--ion-color-primary);
				border-radius: var(--border-radius-toolbar-button);
				font-size: 16px;
				font-weight: lighter;
				letter-spacing: 0;
				margin: 0 4px;
				height: 32px;
				padding: 0 12px;
				text-transform: capitalize;

				ion-icon {
					font-size: 16px;
				}
			}

			.selected {
				color: var(--ion-color-primary-contrast);
				background: var(--ion-color-primary);
			}
		}
	}

	&__content {
		&__name {
			display: flex;
			align-items: center;

			ion-icon {
				color: var(--ion-color-primary);
			}

			span {
				color: var(--ion-color-primary);
				font-size: 12px;
				text-transform: uppercase;
				letter-spacing: 1px;
			}
		}

		&__value {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			font-size: 14px;
		}

		&__details {
			margin-bottom: 16px;
		}
	}

	&__secondary {
		&__source-ids,
		&__contents {
			color: var(--ion-color-medium);
			display: flex;
			align-items: center;
			font-size: 14px;

			ion-badge {
				border-radius: 10px;
				margin: 0 2px;
				padding-left: 6px;
				padding-right: 6px;
			}
		}
	}

	&__instructions {
		a {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

.patient-info2--dark,
.milk-info--dark {
	ion-accordion {
		background-color: var(--ion-color-primary);
	}

	ion-item[slot="header"] {
		--background: var(--ion-color-primary);
		--border-style: none;

		ion-icon {
			color: white;
		}
	}

	ion-list[slot="content"] {
		background-color: transparent;
		//border: 1px solid var(--ion-color-primary-contrast);
		//border-radius: var(--border-radius);
	}

	&--empty {
		border: 1px dashed var(--ion-color-primary-contrast);

		span {
			color: var(--ion-color-primary-contrast);
		}
	}

	&__header {
		&__detail {
			p {
				color: var(--ion-color-primary-contrast);
			}

			&__label {
				color: var(--ion-color-primary-contrast);
			}

			&__value {
				color: var(--ion-color-primary-contrast);

				span:first-child {
				}

				span:last-child {
				}
			}
		}

		&__chip {
		}
	}

	&__content {
		&__name {
			ion-icon {
				color: var(--ion-color-primary-contrast);
			}

			span {
				color: var(--ion-color-primary-contrast);
			}
		}

		&__value {
		}
	}

	.vertical-divider {
		background-color: var(--ion-color-primary-contrast);
	}
}

.milk-info__header {
	&__state,
	&__state--frozen,
	&__state--fresh,
	&__state--thawed,
	&__state--stable,
	&__state--opened,
	&__state--discharged,
	&__state--recalled {
		border-radius: 8px 0 0 8px;
		margin-right: var(--mobile-margin);
	}

	&__state--frozen {
		background: var(--ion-color-frozen-ebm-and-dm);
	}

	&__state--fresh {
		background: var(--ion-color-fresh-ebm);
	}

	&__state--thawed {
		background: var(--ion-color-thawed-ebm-and-dm);
	}

	&__state--stable,
	&__state--opened {
		background: var(--ion-color-stable-and-opened-products);
	}

	&__state--discharged {
		background: #969092;
	}

	&__state--recalled {
		background: #99211f;
	}
}

.milk-details,
.patient-details {
	display: flex;
	align-items: flex-start;
	margin-bottom: 16px;
	font-size: 14px;

	&__icon {
		flex-basis: 16px;
		margin-right: 4px;
		color: var(--ion-color-primary);
		padding: 2px;
	}

	&__label {
		flex-basis: 130px;
		color: var(--ion-color-primary);
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin-right: 8px;
		padding: 2px;
	}
}

.select-item, .select-printer-item, .datetime-item, .feed-state-select-item {
	--padding-start: 0;
	--inner-padding-end: 0;
	//--background-hover: transparent;
	//--background-hover-opacity: 0;
	margin-bottom: 16px;

	ion-col {
		&:first-child {
			padding: 0;
		}
		&:nth-child(2) {
			padding: 0 0 0 10px;
		}
	}

	ion-label {
		margin: 0;
		max-width: 100%;
	}

	ion-input, ion-select {
		--padding-start: 14px;

		min-height: 44px !important;
		height: 44px;
		width: 100%;
	}

	%title-shared {
		color: var(--ion-color-primary);
		font-size: 14px;
		font-weight: normal;
		letter-spacing: 1px;
		margin-bottom: 4px;
	}

	&__title {
		@extend %title-shared;
		text-transform: uppercase;
	}

	&__title_measurement {
		@extend %title-shared;
	}

	&__selector {
		fa-icon {
			position: absolute;
			right: 11px;
			top: 11px;
			z-index: 2;
		}
	}
}

